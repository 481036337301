<template>
<aheader/>

<section class="section">
  <div class="title is-spaced">
    パスワードを忘れた場合
  </div>
  <div class="subtitle">
    ログインパスワードをお忘れになった方は、お手数ですがおぐら屋へ直接ご連絡ください。
    <br>連絡をいただき次第、すぐに対応させていただきます。
  </div>
  <p class="is-size-5">
    電話 <a href="tel:066-954-1515">06-6954-1515</a>
  </p>
</section>

<afooter/>
</template>
<script>
export default {
  mounted() {
    this.scrollTop();
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    }
  }
}
</script>